<template>
  <div
    :id="blok.id"
    v-editable="blok"
    :class="styles"
  >
    <component
      :is="childBlok.component"
      v-for="childBlok in blok.content"
      :key="childBlok._uid"
      :blok="childBlok"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
})

const styles = computed(() => {
  return props.blok.style
})
</script>
